import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Transactions from "../../../Blockchain-transactions/BlockchainTransactions";
import BlockchainScriptHook from "../../../helpers/custom-hooks/BlockchainScriptHook";
import {
  setLoader,
  setLoaderMessage,
} from "../../../redux/features/loading/loaderSlice";
import { useNavigate } from "react-router-dom";
import clientService from "../../../services/client.service";
import Modal from "./../../../common/ModalLayout";
import AlertModalContent from "./../../../components/Modals/AlertModalContent";
import { clientDetailAction } from "../../../redux/features/user/userSlice";
import MatchApproveAndConnectWalletHook from "../../../helpers/custom-hooks/MatchApproveAndConnectWalletHook";
import BlockChainErrorsHandler from "../../../helpers/BlockChainErrorsHandler";
import useLogoutHook from "../../../helpers/custom-hooks/useLogoutHook";
import { images } from "../../../constants";
import InvalidWalletContent from "../../../components/Modals/InvalidWalletContent";

const ClientMultiTxContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientUuid, clientDetail } = useSelector((state) => state?.user);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [openInvalidWalletModal, setOpenInvalidWalletModal] = useState(false);
  const [alertModalContent, setAlertModalContent] = useState({
    title: "",
    description: "",
  });
  const { handleLogout } = useLogoutHook();

  useEffect(() => {
    if (clientDetail?.isClientApproved) {
      if (clientDetail?.capabilityTxId !== null) navigate("/client-dashboard");
    } else {
      if (clientDetail?.stepsCompleted) {
        navigate("/onboarding/social-links");
      } else {
        navigate("/onboarding");
      }
    }
    return () => {};
    //eslint-disable-next-line
  }, []);

  const { getBlockChainScriptFromDB } = BlockchainScriptHook();
  const { matchConnectedWallet, disconnetWallet } =
    MatchApproveAndConnectWalletHook();

  const handleCreateClientCapabilities = async (clientAddress) => {
    dispatch(setLoader(true));
    dispatch(setLoaderMessage("Connecting wallet"));

    const cap_ScriptModal = {
      scriptName: 5,
    };
    const createCapModal = {
      scriptName: 4,
    };
    //const cap_DbScript = await getBlockChainScriptFromDB(cap_ScriptModal);
    // const scriptResult = await Transactions.ExecuteBrandsCapabilityScript(
    //   cap_DbScript,
    //   clientAddress
    // );

    // if (!scriptResult?.error && !scriptResult?.message) {
    //  const matchWalletResponse = await matchConnectedWallet(clientAddress);

    //  if (matchWalletResponse) {
    // dispatch(setLoaderMessage("Checking your brand capability"));
    // const dbScript = await getBlockChainScriptFromDB(createCapModal);
    // const resultData = await Transactions.BrandMultiSignTransaction(
    //   dbScript
    // );

    // if (!resultData?.error) {
    //alert("Capability creation failed")

    const capabilityTxId = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
      /[018]/g,
      (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
    );
    // const capabilityTxId = resultData.message.txId;
    clientService
      .updateClientCapability(clientUuid, capabilityTxId)
      .then((response) => {
        dispatch(setLoader(false));
        dispatch(setLoaderMessage(""));
        if (response?.data?.statusCode === 200) {
          dispatch(clientDetailAction(response?.data?.payload));
          navigate("/client-dashboard");
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
        dispatch(setLoaderMessage(""));
        setOpenResponseModal(true);
        setAlertModalContent({
          title: "Error",
          description: err?.response?.data?.message ?? "something went wrong",
          alertHander: true,
          btnTitle: "Logout",
        });
      });
    // } else {
    //   dispatch(setLoader(false));
    //   dispatch(setLoaderMessage(""));
    //   setOpenResponseModal(true);
    //   let defaultErrMsg =
    //     "Error Occured while Multi Signing Process on blockchain";
    //   let errDescription = BlockChainErrorsHandler(
    //     resultData?.message,
    //     defaultErrMsg
    //   );
    //   setAlertModalContent({
    //     title: "Error",
    //     description: errDescription,
    //     alertHander: true,
    //     btnTitle: "Logout",
    //   });
    // }
    // } else {
    //   if (matchWalletResponse === null) {
    //     dispatch(setLoader(false));
    //     dispatch(setLoaderMessage(""));
    //   } else {
    //     dispatch(setLoader(false));
    //     dispatch(setLoaderMessage(""));
    //     setOpenInvalidWalletModal(true);
    //     await disconnetWallet();
    //   }
    // }
    // }
    // else {
    //   dispatch(setLoader(false));
    //   dispatch(setLoaderMessage(""));
    //   setOpenResponseModal(true);
    //   setAlertModalContent({
    //     title: "Error",
    //     description: "Please contact with support for  assistance  ",
    //     alertHander: true,
    //     btnTitle: "Logout",
    //   });
    // }
  };
  const handleAuthLogout = () => {
    handleLogout();
  };

  return (
    <>
      <div className="flex justify-center h-full">
        <div className="bg-[url('/src/assets/images/connect-wallet-right-bg.png')] bg-no-repeat bg-cover bg-center rounded-3xl pt-20 pb-10 px-14 max-w-[532px] 3xl:max-w-[80%] flex  items-center flex-col">
          <div className="mb-3 grow">
            <img src={images.BloomlyLogoLg} alt="logo" />
          </div>
          <div>
            <div className="mb-8 text-center ">
              <img
                className="inline-block text-center w-14 h-14"
                src={images.WalletIcon}
                alt="icon"
              />
            </div>
            <p className="mb-10 text-xs text-center text-white/70">
              Blocto is an integrated cross-chain wallet service, which enables
              users and developers to interact with their cryptocurrencies,
              dApps, and NFTs frictionlessly.
            </p>
            <button
              className="btn-primary"
              onClick={() =>
                handleCreateClientCapabilities(
                  clientDetail?.clientWalletAddress
                )
              }
            >
              {" "}
              Connect Wallet
            </button>
          </div>
        </div>
      </div>

      {openResponseModal && (
        <Modal
          modalVisible={openResponseModal}
          closeModal={setOpenResponseModal}
          children={
            <AlertModalContent
              message={alertModalContent}
              btnHandler={handleAuthLogout}
            />
          }
          modal_Id="Delete_Modal"
          isClose={true}
        />
      )}
      {openInvalidWalletModal && (
        <Modal
          modalVisible={openInvalidWalletModal}
          closeModal={setOpenInvalidWalletModal}
          children={<InvalidWalletContent />}
          modal_Id="Delete_Modal"
          isClose={true}
        />
      )}
    </>
  );
};

export default ClientMultiTxContent;
