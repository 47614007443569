import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Transactions from "../../../../Blockchain-transactions/BlockchainTransactions";
import { images } from "../../../../constants/images";
import CategorisService from "../../../../services/categories.service";
import RaritiesService from "../../../../services/rarity.service";
import SchemaOptions from "../../../../utils/DropDownOptions/SchemaOptions";
import UserAvatar from "../../components/UserAvatar";
import WalletAddress from "../../components/WalletAddress";
import AssetService from "../../../../services/asset.service";
import ArweaveService from "../../../../services/arweave.service";
import { v4 as uuidv4 } from "uuid";
import BuildFormData from "../../../../helpers/BuildFormData";
import BlockchainScriptHook from "../../../../helpers/custom-hooks/BlockchainScriptHook";
import Modal from "../../../../common/ModalLayout";
import AlertModalContent from "./../../../../components/Modals/AlertModalContent";
import {
  setLoader,
  setLoaderMessage,
} from "../../../../redux/features/loading/loaderSlice";
import MatchApproveAndConnectWalletHook from "../../../../helpers/custom-hooks/MatchApproveAndConnectWalletHook";
import ImmutableComponent from "./assetComponents/createAssetComponent/ImmutableComponent";
import StepperComponent from "../../../../components/ui-components/stepper-component/StepperComponent";
import MutableComponent from "./assetComponents/createAssetComponent/MutableComponent";
import EditionComponent from "./assetComponents/createAssetComponent/EditionComponent";
import { useLocation } from "react-router";
import ArweaveBalanceHook from "../../../../helpers/custom-hooks/FetchArweaveBalance";
import EmailService from "../../../../services/email.service";
import ClientHeaderComponent from "../../components/ClientHeaderComponent";
import ThanksModalContent from "./../../../../components/Modals/ThanksModalContent";
import BlockChainErrorsHandler from "../../../../helpers/BlockChainErrorsHandler";
import InvalidWalletContent from "../../../../components/Modals/InvalidWalletContent";
import GetClientRoyaltyData from "../../../../helpers/custom-hooks/FetchRoyaltyData";
import { getDocumentType } from "../../../../helpers/GetDocumentType";

const CreateAsset = () => {
  /** fetching initial values from our redux store */
  const {
    contactFirstName,
    contactLastName,
    clientWalletAddress,
    clientUuid,
    id,
    blockChainBrandId,
    clientName,
    registerationNo,
  } = useSelector((state) => state?.user?.clientDetail);
  const dispatch = useDispatch();
  const location = useLocation();
  /** hook functions to check client approve wallet and client connected wallet  */
  const { matchConnectedWallet, disconnetWallet } =
    MatchApproveAndConnectWalletHook();

  /** Initialization of react hooks for storing data from api's */
  // const [clientCategories, setClientCategories] = useState([]);
  // const [clientRarities, setClientRarities] = useState([]);
  //const [clientBrandIdData, setClientBrandIdData] = useState();
  const [clientSchemasOptions, setClientSchemasOptions] = useState([]);
  const [openInvalidWalletModal, setOpenInvalidWalletModal] = useState(false);

  /** Hooks for saving values from user input */
  //  const [assetName, setAssetName] = useState("");
  // const [assetDescription, setAssetDescription] = useState("");
  const [editionValue, seteditionValue] = useState(1);
  const EditionMaxRef = useRef();
  const [selectedSchemaType, setSelectedSchemaType] = useState(1);
  const assetCreationScriptRef = useRef();
  /**  schema Hooks */
  const [selectedSchemaFileValue, setSelectedSchemaFileValue] = useState();
  const [schemaUploadDiv, setschemaUploadDiv] = useState("");
  const [uploadedSchemaSrc, setuploadedSchemaSrc] = useState("");
  const [showUploadedSchema, setshowUploadedSchema] = useState("hidden");
  const OrignalImgUrlRef = useRef();
  const croppedImgUrl = useRef();
  const ImageUploadInputRef = useRef();
  const [showCroppingPopup, setshowCroppingPopup] = useState(false);
  const [thumbnailSource, setThumbnailSource] = useState("");
  const arweaveBalanceRef = useRef("0.00");
  // const [selectedRarity, setSelectedRarity] = useState("");
  // const [selectedCategory, setSelectedCategory] = useState("");
  const { getBlockChainScriptFromDB } = BlockchainScriptHook();
  const { getArweaveBalance } = ArweaveBalanceHook();
  const [OpenAlertModal, setOpenAlertModal] = useState(false);
  const [openSuccess, setopenSuccess] = useState(false);
  const [alertContent, setAlertContent] = useState({
    title: "",
    description: "",
    path: "",
  });
  const [fieldIndexImmuteable, setFieldIndexImmuteable] = useState(0);
  const [fieldIndexmuteable, setFieldIndexmuteable] = useState(0);

  const [showImmutableKeyValue, setShowImmutableKeyValue] = useState(false);
  const [showMutableKeyValue, setShowMutableKeyValue] = useState(
    location?.state?.assetData?.mutableData ? true : false
  );
  /** royalty and transferable state */
  const [addRoyalty, setAddRoyalty] = useState(false);
  const [addMaxSupply, setAddMaxSupply] = useState(false);
  const [nonTransferable, setNonTransferable] = useState(false);
  const { getRoyaltyData } = GetClientRoyaltyData();

  const [addedRoyaltyData, setAddedRoyaltyData] = useState([]);

  /** attact document states for uploading file in immutable and mutable  */
  const [addImmutableDocument, setAddImmutableDocument] = useState(false);
  const [addMutableDocument, setAddMutableDocument] = useState(false);
  const [mutableDocumentData, setMutableDocumentData] = useState({});
  /** set royalty Data */

  /** useEffect Hook to fetch basic data for asset creation */
  useEffect(() => {
    getClientSchemaOptions();
    // getClientRarities();
    //getClientCategories();
    //  getClientBrandById();
    getAssetCreationScript();
    fetchArweaveBalance();
    getClientRoyalty();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location?.state?.assetData?.mutableData) {
      const mutableData = location?.state?.assetData?.mutableData;

      Object?.entries(mutableData)?.map(([key, value], i) => {
        if (
          getDocumentType(value) === "image" ||
          getDocumentType(value) === "pdf" ||
          getDocumentType(value) === "txt"
        ) {
          setAddMutableDocument(true);
          setMutableDocumentData({
            ...mutableDocumentData,
            addMutableDocument: true,
            mutableDocumentName: key,
            mutableDocumentFile: value,
          });
        }

      });
      const filterdata = Object.fromEntries(
        Object.entries(mutableData).filter(([key, value]) => {
          if (
            getDocumentType(value) === "image" ||
            getDocumentType(value) === "pdf" ||
            getDocumentType(value) === "txt"
          ) {
            return false;
          } else {
            return true;
          }
        })
      );
      if(Object?.entries(filterdata)?.length > 0) {
        setShowMutableKeyValue(true)
      }else{
        setShowMutableKeyValue(false)
      }
      
  
    }

    return () => {};
  }, []);

  /** Here we are getting schema like image ,audio and video */
  const getClientSchemaOptions = () => {
    const options = SchemaOptions();
    setClientSchemasOptions(options);
  };
  const fetchArweaveBalance = async () => {
    arweaveBalanceRef.current = await getArweaveBalance();
  };
  /** get rarities , currently static ,can be dynamic in future */
  const getClientRarities = () => {
    const rarities = RaritiesService.fetchRarities();
    //  setClientRarities(rarities);
  };
  /** fetch client categories, currently we are using them here, later on we will create custom hook for it  */
  const getClientCategories = () => {
    CategorisService.fetchCategories()
      .then((result) => {
        if (result?.data?.success && result?.data?.payload?.length > 0) {
          const categoryData = result?.data?.payload;
          const data = categoryData
            .sort((a, b) => a.categoryName.localeCompare(b.categoryName))
            .map((item, i) => {
              return {
                label: item.categoryName,
                value: item.id,
              };
            });
          //setClientCategories(data);
        }
      })
      .catch((error) => {
        // setClientCategories([]);
      });
  };

  const getClientRoyalty = async () => {
    dispatch(setLoader(true));
    const repsonse = await getRoyaltyData(clientUuid);
    if (repsonse?.length > 0) {
      setAddedRoyaltyData(repsonse);
    }
  };

  const getAssetCreationScript = async () => {
    const scriptModal = {
      scriptName: location?.state?.assetData === undefined ? 1 : 12,
    };
    const bc_AssetTx = await getBlockChainScriptFromDB(scriptModal);
    assetCreationScriptRef.current = bc_AssetTx;
  };

  const editionValuePlusHandler = (e) => {
    e.preventDefault();
    let editionVal = editionValue;
    if (parseInt(editionVal) === 0) editionVal = 0;
    if (parseInt(editionVal) >= 100000) editionVal = 100000;
    else editionVal = parseInt(editionVal + 1);

    seteditionValue(editionVal);
    EditionMaxRef.current = editionVal;
    EditionMaxRef?.current?.focus();
  };
  const editionValueMinusHandler = (e) => {
    e.preventDefault();
    let editionVal = editionValue;
    editionVal = editionVal - 1;
    if (editionVal <= 1) editionVal = 1;
    seteditionValue(editionVal);
    EditionMaxRef.current = editionVal;
    EditionMaxRef?.current?.focus();
  };
  const EditionInputChangeHandler = (evt) => {
    let EditionValue = evt.target.validity.valid
      ? evt.target.value
      : editionValue;
    if (EditionValue !== "") {
      if (parseInt(EditionValue) === 0) EditionValue = 1;
      if (parseInt(EditionValue) > 100000) EditionValue = 100000;
      seteditionValue(parseInt(EditionValue));
      EditionMaxRef.current = parseInt(EditionValue);
    } else {
      seteditionValue(EditionValue);
      EditionMaxRef.current = EditionValue;
    }
    // EditionMaxRef?.current?.focus();
  };
  const UploadSchemaHandler = (e) => {
    // ImageUploadInputRef?.current?.click();

    if (e?.target?.value) e.target.value = null;
    ImageUploadInputRef.current = "";
  };
  const crossImgClickHandler = (e) => {
    setIsMediaSelect({ ...isMediaSelect, status: false });
    setshowUploadedSchema("hidden");
    setschemaUploadDiv("");
    croppedImgUrl.current = null;
    setuploadedSchemaSrc();
    OrignalImgUrlRef.current = null;
    setSelectedSchemaFileValue();
    UploadSchemaHandler(e);
  };
  const assetCreationErrorMsgs = (erroMsg) => {
    dispatch(setLoader(false));
    dispatch(setLoaderMessage(""));
    setOpenAlertModal(true);
    setAlertContent({
      title: "Error",
      description: erroMsg,
    });
  };

  const AssetCreationHandler = async (royalty) => {
    let assetTypeValue = ImmutableData?.assetType?.value;
    dispatch(setLoader(true));
    dispatch(
      setLoaderMessage(
        "Please wait. Your asset is being created on the blockchain."
      )
    );
   
    let brandId = blockChainBrandId;
    let maxSupply = editionValue;
    let hashValue = "";
    let arweaveImgUrl = "";
    const response = await uploadImageOnArweave(selectedSchemaFileValue);

    arweaveImgUrl = response?.arweaveImgUrl;
    hashValue = response?.hashValue;
    // upload attach document add in immutable component
    let arweaveImmutableFile = "";

    if (ImmutableData?.addImmutableDocument) {
      let immutableDocument = ImmutableData?.immutableDocumentFile[0];
      const response = await uploadImageOnArweave(immutableDocument);
      arweaveImmutableFile = response?.arweaveImgUrl;
    }

    if (ImmutableData?.addImmutableDocument && !arweaveImmutableFile) {
      return;
    }
  
    // upload attach document to arweave added in mutable component
    let arweaveMutableFile = "";
    if (mutableDocumentData?.addMutableDocument) {
      let mutableDocument = mutableDocumentData?.mutableDocumentFile[0];
      const response = await uploadImageOnArweave(mutableDocument);
      arweaveMutableFile = response?.arweaveImgUrl;
    }
    if (mutableDocumentData?.addMutableDocument && !arweaveMutableFile) {
      return;
    }

    //     let ImmutableKeyValue=inputImmutableFields.map(result=>{
    //       return ({[result.fieldKey]:result.fieldValue});
    //   });
    //   let mutableKeyValue=mutableInputFields.map(result=>{
    //     return ({[result.fieldKey]:result.fieldValue});
    // })
    let ImmutableKeyValue = inputImmutableFields.map((res) => {
      const { fieldKey: key, fieldValue: value } = res;
      return { key, value };
    });
    ImmutableKeyValue = ImmutableKeyValue?.filter((data) => {
      return data?.key !== "" && data?.value !== "";
    });
    let mutableKeyValue = mutableInputFields.map((res) => {
      const { fieldKey: key, fieldValue: value } = res;
      return { key, value };
    });
    if (
      mutableKeyValue?.length === 1 &&
      mutableKeyValue?.[0]?.key === "" &&
      mutableKeyValue?.[0].value === ""
    ) {
      mutableKeyValue = null;
    }
  
    if (mutableDocumentData?.addMutableDocument) {
      let mutableDocument = {
        key: mutableDocumentData.mutableDocumentName,
        value: arweaveMutableFile,
      };
      if (mutableKeyValue) {
        mutableKeyValue = [...mutableKeyValue, mutableDocument];
      } else {
        mutableKeyValue = [mutableDocument];
      }
    }

    let bcMaxSupply = addMaxSupply ? null : maxSupply?.toString();
    let blockChainModel = [
      // { key: "brandId", value: brandId?.toString() },
      // { key: "maxSupply", value: bcMaxSupply },
      { key: "ClientName", value: clientName },
      { key: "Asset_Title", value: ImmutableData?.assetName },
      {
        key: "Asset_Description",
        value: ImmutableData?.assetDescription?.trim(),
      },
      {
        key: "ArweaveAssetType",
        value:
          parseInt(assetTypeValue) === 1
            ? "Image"
            : parseInt(assetTypeValue) === 2
            ? "Audio"
            : parseInt(assetTypeValue) === 3
            ? "Video"
            : "Image",
      },
      { key: "ArweaveAssetUri", value: arweaveImgUrl },
      { key: "ClientUuid", value: clientUuid },
      {
        key: "Date_Created",
        value: new Date().toLocaleDateString("en-US", {
          day: "numeric",
          month: "long",
          year: "numeric",
        }),
      },
    ];

    ImmutableKeyValue = [...ImmutableKeyValue, ...blockChainModel];
    if (ImmutableData?.addImmutableDocument) {
      let immutableDocument = {
        key: ImmutableData.immutableDocumentName,
        value: arweaveImmutableFile,
      };
      ImmutableKeyValue = [...ImmutableKeyValue, immutableDocument];
    }
  
    // const matchWalletResponse = await matchConnectedWallet(clientWalletAddress);
    // if (matchWalletResponse) {
    //   let royaltiesAddress;
    //   let royaltiesDescription;
    //   let royaltiesCut;
    //   if (addRoyalty && Object.entries(royalty)?.length > 0) {
    //     royaltiesAddress = royalty?.receiverAddress;
    //     royaltiesDescription = royalty?.description;
    //     royaltiesCut = royalty?.royaltyPercentage?.map((value) =>
    //       parseFloat(value / 100).toFixed(2)
    //     );
    //   } else {
    //     royaltiesAddress = [];
    //     royaltiesDescription = [];
    //     royaltiesCut = [];
    //   }
      // const createAssetResponse =
      //   await Transactions.CreateAssetExecuteTransaction(
      //     assetCreationScriptRef.current,
      //     ImmutableKeyValue,
      //     mutableKeyValue,
      //     brandId,
      //     bcMaxSupply,
      //     !nonTransferable, // if non-transferable is false mean asset will be transferable else if non-transferable is it mean asset will be non-transferable .for backend And BlockChain we oppsite the condtion with '!'sign because the key name is transferable in script and api
      //     royaltiesAddress,
      //     royaltiesDescription,
      //     royaltiesCut,
      //     addRoyalty
      //   );
      //if (!createAssetResponse?.error) {

        const TxId = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
          /[018]/g,
          (c) =>
            (
              c ^
              (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
            ).toString(16)
        );

        let createAssetModel = {
          AssetId: Math.floor(Math. random() * (1500 - 500) + 500),
          ClientName: clientName,
          ClientId: clientUuid,
          maxSupply: addMaxSupply ? 0 : maxSupply?.toString(),
          BlockChainTransactionId: TxId,
          AssetTitle: ImmutableData?.assetName,
          AssetDescription: ImmutableData?.assetDescription,
          ArtworkHash: hashValue,
          ArweaveAssetType:
            parseInt(assetTypeValue) === 1
              ? "Image"
              : parseInt(assetTypeValue) === 2
              ? "Audio"
              : parseInt(assetTypeValue) === 3
              ? "Video"
              : "Image",
          ArweaveAssetUri: arweaveImgUrl,
          ImmutableData: ImmutableKeyValue,
          MutableData: mutableKeyValue,
          IsTransferable: !nonTransferable, // if non-transferable is false mean asset will be transferable else if non-transferable is it mean asset will be non-transferable .for backend And BlockChain we oppsite the condtion with '!'sign because the key name is transferable in script and api
        };

        if (addRoyalty && Object.entries(royalty)?.length > 0) {
          createAssetModel.RoyaltyJson = JSON.stringify(royalty);
        }
        const form = new FormData();
        if (createAssetModel?.ArweaveAssetType === "Image") {
          createAssetModel.AssetCroppedImage =
            croppedImgUrl.current ?? OrignalImgUrlRef.current;
          createAssetModel.AssetOriginalImage = OrignalImgUrlRef.current;
          // blockChainModel.Dimensions = img.width + "x" + img.height;
          BuildFormData(form, createAssetModel);
        } else if (createAssetModel?.ArweaveAssetType === "Audio") {
          createAssetModel.AudioUri = arweaveImgUrl;
          BuildFormData(form, createAssetModel);
        } else if (createAssetModel?.ArweaveAssetType === "Video") {
          createAssetModel.VideoUri = arweaveImgUrl;
          createAssetModel.AssetThumnailImage = thumbnailSource;
          BuildFormData(form, createAssetModel);
        }
        try {
          const assetResult = await AssetService.createAsset(form);

          if (assetResult?.data?.success) {
            dispatch(setLoader(false));
            dispatch(setLoaderMessage(""));
            setopenSuccess(true);
            setAlertContent({
              title: "Success",
              description:
                " Your asset has been created successfully on the blockchain. You may proceed now to attach this asset under Drops. ",
              path: "/asset-list",
              subTitle: "Thank you for waiting patiently. ",
              btnTitle: "Back to Assets",
            });

            //  navigate("/asset-list");
          } else {
            assetCreationErrorMsgs("Error occured while saving asset in db ");
            return;
          }
        } catch (ex) {
          assetCreationErrorMsgs(
            ex?.response?.data?.message ??
              "Error occured while saving asset in db "
          );
          return;
        }
      // } else {
      //   let defaultErrMsg = "Error occured while creating asset on blockchain";
      //   let bcError = BlockChainErrorsHandler(
      //     createAssetResponse?.message,
      //     defaultErrMsg
      //   );
      //   assetCreationErrorMsgs(bcError);
      //   return;
      // }
    // } else {
    //   if (matchWalletResponse === null) {
    //     dispatch(setLoader(false));
    //     dispatch(setLoaderMessage(""));
    //   } else {
    //     setOpenInvalidWalletModal(true);
    //     dispatch(setLoader(false));
    //     dispatch(setLoaderMessage(""));
    //     await disconnetWallet();

    //     return;
    //   }
    // }
  };
  //dispatch(setLoader(false));

  const updateAssetHandler = async (e, mutableDocumentData) => {
   
    e?.preventDefault();
    dispatch(setLoader(true));

    // upload attach document to arweave added in mutable component
    let arweaveMutableFile = "";
    if (mutableDocumentData?.addMutableDocument) {
      if (typeof mutableDocumentData?.mutableDocumentFile == "string") {
        arweaveMutableFile = mutableDocumentData?.mutableDocumentFile;
      } else {
        let mutableDocument = mutableDocumentData?.mutableDocumentFile[0];
        const response = await uploadImageOnArweave(mutableDocument);
        arweaveMutableFile = response?.arweaveImgUrl;
      }
    }
    if (mutableDocumentData?.addMutableDocument && !arweaveMutableFile) {
      return;
    }

    let mutableKeyValue = mutableInputFields.map((res) => {
      const { fieldKey: key, fieldValue: value } = res;
      return { key, value };
    });
    if (
      mutableKeyValue?.length === 1 &&
      mutableKeyValue?.[0]?.key === "" &&
      mutableKeyValue?.[0].value === ""
    ) {
      mutableKeyValue = null;
    }
    if (mutableDocumentData?.addMutableDocument) {
      let mutableDocument = {
        key: mutableDocumentData.mutableDocumentName,
        value: arweaveMutableFile,
      };
      if (mutableKeyValue) {
        mutableKeyValue = [...mutableKeyValue, mutableDocument];
      } else {
        mutableKeyValue = [mutableDocument];
      }
    }

    if (mutableKeyValue?.length >= 1) {
      let bEmptyPair = mutableKeyValue?.filter((data) => {
        return data?.key === "" || data?.value === "";
      });
      if (bEmptyPair?.length > 0) {
        assetCreationErrorMsgs("Please enter key value pair");
        return;
      }
    } else {
      assetCreationErrorMsgs("Please enter key value pair");
      return;
    }
    const matchWalletResponse = await matchConnectedWallet(clientWalletAddress);
    if (matchWalletResponse) {
      dispatch(
        setLoaderMessage(
          "Please wait. Your asset is being updated on the blockchain."
        )
      );
      let templateIdValue = location?.state?.assetData?.templateId;
      const formData = new FormData();
      const updateAssetModal = {
        assetId: templateIdValue,
        mutableData: mutableKeyValue,
        ClientId: clientUuid,
      };
      BuildFormData(formData, updateAssetModal);

      const updateAssetTx = await Transactions.UpdateAssetTransaction(
        assetCreationScriptRef.current,
        mutableKeyValue,
        templateIdValue
      );
      if (!updateAssetTx?.error) {
        AssetService.updateAsset(formData)
          .then((assetResult) => {
            if (assetResult?.data?.payload?.errorCode === 0) {
              dispatch(setLoader(false));
              dispatch(setLoaderMessage(""));
              setOpenAlertModal(true);
              setAlertContent({
                title: "Success",
                description:
                  "Your asset has been updated successfully on the blockchain. ",
                path: "/asset-list",
              });
            } else {
              dispatch(setLoader(false));
              dispatch(setLoaderMessage(""));
              setOpenAlertModal(true);
              setAlertContent({
                title: "Error",
                description: "Error Occured while updating asset in db ",
              });
            }
          })
          .catch((error) => {
            dispatch(setLoader(false));
            dispatch(setLoaderMessage(""));
            setOpenAlertModal(true);
            setAlertContent({
              title: "Error",
              description: "Error Occured while updating asset in db ",
            });
          });
      } else {
        let defaultErrMsg = "Error occured while updating asset on blockchain";
        let bcError = BlockChainErrorsHandler(
          updateAssetTx?.message,
          defaultErrMsg
        );
        assetCreationErrorMsgs(bcError);
      }
    } else {
      if (matchWalletResponse === null) {
        dispatch(setLoader(false));
      } else {
        dispatch(setLoader(false));
        setOpenInvalidWalletModal(true);
        await disconnetWallet();
      }
    }
  };

  /** upload  image on arweave  */

  const uploadImageOnArweave = async (media) => {
    let hashForm = new FormData();
    let hashValue = "";
    let arweaveUploadedTx = "";
    let arweaveImgTx = "";
    let arweaveImgUrl = "";
    const ext = media.name.replace(/^.*\./, "");
    hashForm.append("AssetFile", media);

    try {
      const checkHashGenerated = await AssetService.generateAssetHash(hashForm);
      if (
        checkHashGenerated?.data?.success &&
        checkHashGenerated?.data?.payload != null
      ) {
        hashValue = checkHashGenerated?.data?.payload?.item1;
        arweaveUploadedTx = checkHashGenerated?.data?.payload?.item2;
      } else {
        assetCreationErrorMsgs("Failed to create Hash, please try again ");
        return;
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        assetCreationErrorMsgs("Failed to create Hash, please try again ");
        return;
      }
    }

    if (!arweaveUploadedTx) {
      /** Here we will check for arweave file size in future via api , for now let's simply call upload */
      const formData = new FormData();
      formData.append("file", media);
      formData.append("clientName", clientName + "_" + registerationNo);
      formData.append("AssetName", ImmutableData?.assetName ?? location?.state?.assetData?.Asset_Title);
      formData.append("clientId", clientUuid);
      let arweaveConfig = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
        },
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          //   setprogress(percentage);

          if (percentage === 100) {
            setTimeout(() => {
              //   setprogress(0);
            }, 400);
          }
        },
      };
      const txId = await ArweaveService.uploadToArweave(formData, arweaveConfig)
        .then((arResp) => {
          return arResp;
        })
        .catch((err) => {
          return err;
        });

      if (txId?.success) {
        arweaveImgTx = txId.trnsactionId;
      } else {
        assetCreationErrorMsgs(
          txId.message ?? "Error occured while uploading asset to arweave"
        );

        return;
      }
      if (
        parseFloat(arweaveBalanceRef.current) <
        parseFloat(process.env.REACT_APP_ARWEAVE_THRESHOLD)
      ) {
        const modal = {
          Tokens: arweaveBalanceRef.current,
        };
        EmailService.sendAlertEmailToSuperAdmin(modal);
      }

      const arweaveTestnetUrl = "https://testnet.redstone.tools/";
      const arweaveMainnetUrl = "https://arweave.net/";
      arweaveImgUrl =
        arweaveMainnetUrl + arweaveImgTx + "/" + uuidv4() + "." + ext;
      // save hash on db so that to prevent uploading same file on arweave next time
      try {
        let payload = {
          assetHash: hashValue,
          assetArweaveUrl: arweaveImgUrl,
        };
        await AssetService.saveUploadedAssetHash(payload);
      } catch (error) {
        if (error?.response?.status !== 401) {
          assetCreationErrorMsgs("Failed to save Hash, please try again ");
          return;
        }
      }

      return { arweaveImgUrl, hashValue };
    } else {
      arweaveImgUrl = arweaveUploadedTx;
      return { arweaveImgUrl, hashValue };
    }
  };

  /** Add dynamic set values */
  const [inputImmutableFields, setImmutableInputFields] = useState([
    { fieldKey: "", fieldValue: "" },
  ]);
  const [ImmutableData, setImmutableData] = useState({});
  const [mutableInputFields, setMutableInputFields] = useState([
    { fieldKey: "", fieldValue: "" },
  ]);
  const [checkMaxSupplyFlag, setCheckMaxSupplyFlag] = useState(false);
  const [isMediaSelect, setIsMediaSelect] = useState({
    status: false,
    message: "Please select media",
  });
  const [activeStep, setActiveStep] = useState(
    location?.state?.assetData ? 2 : 1
  );

  const [steps, setSteps] = useState([
    { title: "Immutable", value: 1, IsCompleted: false },
    { title: "Mutable", value: 2, IsCompleted: false },
    { title: "Edition", value: 3, IsCompleted: false },
  ]);

  return (
    <>
      <main className="relative z-20 ">
        <ClientHeaderComponent
          title={location?.state?.assetData ? "Update Asset" : "Create Asset"}
        />

        {/** stepper start */}
        <StepperComponent steps={steps} activeStep={activeStep} />

        {/** stepper end */}

        {/* <div className="mb-8 relative before:bg-pink-500 before:absolute before:w-[2px] before:h-full before:top-[1px] before:left-0 before:border-radius-top-right">
          <p className="mt-2 mb-2 ml-4 text-base ">
            Arweave Free Space:
            <span className="font-semibold text-pink-500"> 1 GB</span>{" "}
          </p>
        </div> */}

        {/* my content */}
        {activeStep === 1 ? (
          <>
            <ImmutableComponent
              isMediaSelect={isMediaSelect}
              setIsMediaSelect={setIsMediaSelect}
              ImmutableData={ImmutableData}
              setImmutableData={setImmutableData}
              inputImmutableFields={inputImmutableFields}
              setImmutableInputFields={setImmutableInputFields}
              schemaUploadDiv={schemaUploadDiv}
              setschemaUploadDiv={setschemaUploadDiv}
              UploadSchemaHandler={UploadSchemaHandler}
              setshowUploadedSchema={setshowUploadedSchema}
              showUploadedSchema={showUploadedSchema}
              setuploadedSchemaSrc={setuploadedSchemaSrc}
              uploadedSchemaSrc={uploadedSchemaSrc}
              OrignalImgUrlRef={OrignalImgUrlRef}
              setSelectedSchemaFileValue={setSelectedSchemaFileValue}
              ImageUploadInputRef={ImageUploadInputRef}
              croppedImgUrl={croppedImgUrl}
              showCroppingPopup={showCroppingPopup}
              setshowCroppingPopup={setshowCroppingPopup}
              crossImgClickHandler={crossImgClickHandler}
              selectedSchemaType={selectedSchemaType}
              setThumbnailSource={setThumbnailSource}
              thumbnailSource={thumbnailSource}
              clientSchemasOptions={clientSchemasOptions}
              setSelectedSchemaType={setSelectedSchemaType}
              setActiveStep={setActiveStep}
              OpenAlertModal={OpenAlertModal}
              setOpenAlertModal={setOpenAlertModal}
              setAlertContent={setAlertContent}
              alertContent={alertContent}
              setFieldIndex={setFieldIndexImmuteable}
              fieldIndex={fieldIndexImmuteable}
              setSteps={setSteps}
              steps={steps}
              showImmutableKeyValue={showImmutableKeyValue}
              setShowImmutableKeyValue={setShowImmutableKeyValue}
              assetCreationErrorMsgs={assetCreationErrorMsgs}
              addImmutableDocument={addImmutableDocument}
              setAddImmutableDocument={setAddImmutableDocument}
            />
          </>
        ) : (
          <>
            {activeStep === 2 ? (
              <>
                <MutableComponent
                  steps={steps}
                  setSteps={setSteps}
                  fieldIndex={fieldIndexmuteable}
                  setFieldIndex={setFieldIndexmuteable}
                  setOpenAlertModal={setOpenAlertModal}
                  setAlertContent={setAlertContent}
                  alertContent={alertContent}
                  updateAssetHandler={updateAssetHandler}
                  mutableFilledData={location?.state?.assetData?.mutableData}
                  mutableInputFields={mutableInputFields}
                  setMutableInputFields={setMutableInputFields}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  showMutableKeyValue={showMutableKeyValue}
                  setShowMutableKeyValue={setShowMutableKeyValue}
                  addMutableDocument={addMutableDocument}
                  setAddMutableDocument={setAddMutableDocument}
                  setMutableDocumentData={setMutableDocumentData}
                  mutableDocumentData={mutableDocumentData}
                />
              </>
            ) : (
              <>
                <EditionComponent
                  steps={steps}
                  setSteps={setSteps}
                  setActiveStep={setActiveStep}
                  EditionInputChangeHandler={EditionInputChangeHandler}
                  editionValueMinusHandler={editionValueMinusHandler}
                  editionValue={editionValue}
                  seteditionValue={seteditionValue}
                  EditionMaxRef={EditionMaxRef}
                  editionValuePlusHandler={editionValuePlusHandler}
                  checkMaxSupplyFlag={checkMaxSupplyFlag}
                  setCheckMaxSupplyFlag={setCheckMaxSupplyFlag}
                  AssetCreationHandler={AssetCreationHandler}
                  addRoyalty={addRoyalty}
                  setAddRoyalty={setAddRoyalty}
                  nonTransferable={nonTransferable}
                  setNonTransferable={setNonTransferable}
                  addMaxSupply={addMaxSupply}
                  setAddMaxSupply={setAddMaxSupply}
                  setAddedRoyaltyData={setAddedRoyaltyData}
                  addedRoyaltyData={addedRoyaltyData}
                />
              </>
            )}
          </>
        )}
      </main>

      {OpenAlertModal && (
        <Modal
          modalVisible={OpenAlertModal}
          closeModal={setOpenAlertModal}
          children={<AlertModalContent message={alertContent} />}
          modal_Id="Add_Modal"
          isClose={true}
        />
      )}
      {openSuccess && (
        <Modal
          modalVisible={openSuccess}
          closeModal={setopenSuccess}
          children={<ThanksModalContent message={alertContent} />}
          modal_Id="Add_Modal"
          isClose={true}
        />
      )}
      {openInvalidWalletModal && (
        <Modal
          modalVisible={openInvalidWalletModal}
          closeModal={setOpenInvalidWalletModal}
          children={<InvalidWalletContent />}
          modal_Id="Delete_Modal"
          isClose={true}
        />
      )}
    </>
  );
};
export default CreateAsset;
