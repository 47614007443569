import { useSelector } from "react-redux";
import Transactions from "../../Blockchain-transactions/BlockchainTransactions";
import BlockchainScriptHook from "./BlockchainScriptHook";
import AssetService from "../../services/asset.service";

const FetchTemplatesFromBC = () => {
  const { blockChainBrandId } = useSelector(
    (state) => state?.user?.clientDetail
  );
  const { clientDetail } = useSelector((state) => state?.user);

  const fetchTemplatesList = async () => {
    try {
      const response = await AssetService.getAllAssetsFromDB(clientDetail.clientUuid);
       
      // Handle and return the API response
      return response.data.payload.result;
    } catch (error) {
      console.error("Error fetching templates:", error);
      return null;
    }
    // const scriptModal = {
    //   scriptName: 7,
    // };

    // const dbscript = await getBlockChainScriptFromDB(scriptModal);

    //   const scriptResult = await Transactions.ExecuteGetAssetScripts(
    //     dbscript,
    //     blockChainBrandId?.toString()
    //   );
    //   if (scriptResult?.error === false && scriptResult?.message != null)
    //     return scriptResult?.message;
    //   else return null;
    // };
  };

  // Return the fetchTemplatesList function
  return {
    fetchTemplatesList, // Ensure this is inside the return object
  };
};

export default FetchTemplatesFromBC;
