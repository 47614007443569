import { images } from "../../../../constants";
import DateFormat from "../../../../helpers/DateFormat";
import stringTruncate from "../../../../helpers/StringTruncate";

export const COLUMNS = [
  {
    Header: "Asset",
    Footer: "Asset",
    accessor: "arweaveAssetUri",
    Cell: ({ row }) => (
      <>
        {row?.values?.arweaveAssetType === "Image" && (
          <img
            className=" rounded w-6 h-6 "
            onLoad={(e) => {
              e.currentTarget.attributes.src.value =
                row?.values?.arweaveAssetUri;
            }}
            src={images.DefaultImageIcon}
            alt="icon"
          />
        )}
        {row?.values?.ArweaveAssetType === "Video" && (
          <img
            className=" rounded w-6 h-6 "
            src={images.DefaultImageIcon}
            alt="icon"
          />
        )}
        {row?.values?.ArweaveAssetType === "Audio" && (
          <img
            className=" rounded w-6 h-6 "
            src={images.AudioIcon}
            alt="icon"
          />
        )}
      </>
    ),
  },
  {
    Header: "Asset Name",
    Footer: "Asset Name",
    accessor: "asset_Title",
    Cell: ({ row }) => (
      <>{<p>{stringTruncate(row?.values?.asset_Title, 12)}</p>}</>
    ),
  },
  {
    Header: "Asset Type",
    Footer: "Asset Type",
    accessor: "arweaveAssetType",
  },

  {
    Header: "Max Supply",
    Footer: "Max Supply",
    accessor: "maxSupply",
    Cell: ({ row }) => (
      <>
        <p>
          {row?.values?.maxSupply === null ? (
            <p>Unlimited</p>
          ) : (
            row?.values?.maxSupply
          )}
        </p>
      </>
    ),
  },
  {
    Header: "Asset Transfer",
    Footer: "Asset Transfer",
    accessor: "transferable",
    Cell: ({ row }) => (
      <>
        <p>
          {row?.values?.transferable ? (
            <>
              <img className="mr-1 inline-block" src={images.TransferableIcon} alt="icon" />
              <span>    Transferable
              </span>
            </>
          ) : (
            <>
              <img className="mr-1 inline-block" src={images.NonTransferableIcon} alt="icon" />
              <span>  Non-transferable
              </span>
            </>
          )}
        </p>
      </>
    ),
  },
  {
    Header: "Created",
    Footer: "Created",
    accessor: "date_Created",
    Cell: ({ row }) => (
      <>
          {
              <p>
                  {row?.original?.date_Created &&
                      (
                          <div className="flex">
                              <p>
                                  <DateFormat date={row?.original?.date_Created} />
                              </p>
                          </div>
                      )}
              </p>
          }
      </>
  ),
  },
];
