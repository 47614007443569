import { useEffect, useRef, useState } from "react";
import FileResizer from "react-image-file-resizer";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import { images } from "../../../../constants";
import FetchTemplatesFromBC from "../../../../helpers/custom-hooks/FetchTemplatesFromBC";
import dataUrlToFile from "../../../../helpers/dataUrlToFile";
import DatePickerHelper from "../../../../helpers/DatePickerHelper";
import {
  setLoader,
  setLoaderMessage,
} from "../../../../redux/features/loading/loaderSlice";
import DropService from "../../../../services/drop.services";
import UserAvatar from "../../components/UserAvatar";
import WalletAddress from "../../components/WalletAddress";
import VideoThumbnail from "react-video-thumbnail";
import Modal from "../../../../common/ModalLayout";
import ImageCropper from "../../../../components/Modals/ImageCropper";
import BuildFormData from "../../../../helpers/BuildFormData";
import moment from "moment";
import BlockchainScriptHook from "../../../../helpers/custom-hooks/BlockchainScriptHook";
import Transactions from "../../../../Blockchain-transactions/BlockchainTransactions";
import script from "../../../../blockchain-scripts/BlockchainScripts";
import ModalLayout from "../../../../common/ModalLayout";
import AlertModalContent from "../../../../components/Modals/AlertModalContent";
import MatchApproveAndConnectWalletHook from "../../../../helpers/custom-hooks/MatchApproveAndConnectWalletHook";
import { useLocation } from "react-router";
import CreateDropDetail from "./DropComponents/CreateDropDetail";
import AddDropAsset from "./DropComponents/AddDropAsset";
import DropReview from "./DropComponents/DropReview";
import StepperComponent from "./../../../../components/ui-components/stepper-component/StepperComponent";
import ThanksModalContent from "../../../../components/Modals/ThanksModalContent";
import BlockChainErrorsHandler from "../../../../helpers/BlockChainErrorsHandler";
import InvalidWalletContent from "../../../../components/Modals/InvalidWalletContent";
import { removelineSpace } from "../../../../helpers/RemoveSpecialCharSpacline";
import { replaceQuotes } from "../../../../helpers/ReplaceQuotes";
import { replaceslashwithquote } from "../../../../helpers/ReplaceSlachWithQuotes";

const CreateDrop = () => {
  const {
    contactFirstName,
    contactLastName,
    clientWalletAddress,
    clientUuid,
    id,
    blockChainBrandId,
    clientName,
  } = useSelector((state) => state?.user?.clientDetail);
  const userInfo = useSelector((state) => state?.user?.userInfo);
  const location = useLocation();
  const { getBlockChainScriptFromDB } = BlockchainScriptHook();
  const { fetchTemplatesList } = FetchTemplatesFromBC();
  const { matchConnectedWallet, disconnetWallet } =
    MatchApproveAndConnectWalletHook();
  const dispatch = useDispatch();
  const [AssetsArray, setAssetsArray] = useState([]);
  const [selectedAssetArray, setSelectedAssetArray] = useState([]);
  const [imageChecked, setImageChecked] = useState([]);
  const [bannerImgSrc, setBannerImgSrc] = useState("");
  const [bannerThumbnailSrc, setBannerThumbnailSrc] = useState("");
  const videoThumbnailSrc = useRef();
  const [bannerVideoSrc, setBannerVideoSrc] = useState("");
  const [showCroppingPopup, setshowCroppingPopup] = useState(false);
  const [publicSaleStartDate, setPublicSaleStartDate] = useState("");
  const [publicSaleEndDate, setPublicSaleEndDate] = useState("");
  const [dropPrice, setDropPrice] = useState("");
  const [dropName, setDropName] = useState("");
  const [dropDescription, setDropDescription] = useState("");
  const [assetUploadingDiv, setAssetUploadingDiv] = useState("");
  const [DropBannerImg, setDropBannerImg] = useState("");
  const [selectCheckboxes, setSelectCheckboxes] = useState("");
  const createDropScript = useRef();
  const [OpenAlertModal, setOpenAlertModal] = useState(false);
  const viewEditDropDataRef = useRef();
  const [videoThumbNailViewOnly, setVideoThumbnailViewOnly] = useState("");
  const [alertContent, setAlertContent] = useState({
    title: "",
    description: "",
    path: "",
  });
  const [activeStep, setActiveStep] = useState(1);
  const [dropDetailData, setDropDetailData] = useState({});
  const [dropAssetData, setDropAssetData] = useState([]);
  const [openSuccess, setopenSuccess] = useState(false);
  const [assetSearchValue, setAssetSearchValue] = useState("")

  // Claim states
  const [addMaxClaim, setAddMaxClaim] = useState();
  const [claimValue, setClaimValue] = useState(1);
  const [isMaxClaim, setIsMaxClaim] = useState(false);
  const [addClaimLimit, setAddClaimLimit] = useState(false);
  const [isWhitelistSection, setIsWhitelistSection] = useState(false);
  const [addWhitelistSwitch, setAddWhitelistSwitch] = useState(false);
  const [selectedWhitelistOption, setSelectedWhitelistOption] = useState("");
  const [airdropUserList, setAirdropUserList] = useState([]);

  const claimMaxRef = useRef();

  const [steps, setSteps] = useState([
    { title: "Drop Details", value: 1, IsCompleted: false },
    { title: "Select Asset", value: 2, IsCompleted: false },
    { title: "Review", value: 3, IsCompleted: false },
  ]);
  const [openInvalidWalletModal, setOpenInvalidWalletModal] = useState(false);

  const croppedImgUrl = useRef();
  const originalAssetsArray = useRef();
  const retryBlockChain = useRef();
  useEffect(() => {
    if (location?.state?.uuid) {
      fetchDropDetailsByUuId(location?.state?.uuid);
    } else {
      fetchDropTemplates();
    }
    //eslint-disable-next-line
  }, []);
  const fetchDropTemplates = async () => {
    dispatch(setLoader(true));
    let templateList = await fetchTemplatesList();
  
    dispatch(setLoader(false));
    if (templateList != null) {
      templateList = Object.values(templateList);
      let templateIdsFromDB = await DropService.getDropAssetIds(clientUuid);
      // if (templateIdsFromDB?.data?.payload?.length > 0) {
      //   templateList = templateList?.filter((item) => {
      //     if (location?.state?.templateId != null && !location?.state?.isEdit) {
      //       return parseInt(item.templateId) === location?.state?.templateId;
      //     } else if (
      //       location?.state?.templateId != null &&
      //       location?.state?.isEdit
      //     ) {
      //       return (
      //         parseInt(item.templateId) === location?.state?.templateId ||
      //         templateIdsFromDB?.data?.payload?.indexOf(
      //           parseInt(item?.templateId)
      //         ) >= 0
      //       );
      //     } else
      //       return (
      //         templateIdsFromDB?.data?.payload?.indexOf(
      //           parseInt(item?.templateId)
      //         ) >= 0
      //       );
      //   });
      // }
      
      templateList = templateList.map((ele, i) => ({
        artSrc: ele?.arweaveAssetUri,
        id: ele?.bcTemplateId,
        assetName: ele?.asset_Title,
        templateType: ele?.arweaveAssetType,
        isChecked:
          parseInt(ele?.templateId) === location?.state?.templateId ??
          viewEditDropDataRef.current?.blockChainTemplateId,
          transferable:ele?.transferable
      }));
     
      templateList = templateList?.reverse();

      setAssetsArray(templateList);
      originalAssetsArray.current = templateList;
      /** Populate Drop Banner Image in case of edit drop  */
      if (viewEditDropDataRef.current) {
        setAssetUploadingDiv("hidden");
        if (viewEditDropDataRef.current?.docURI?.split(".").pop() === "mp4") {
          setVideoThumbnailViewOnly(
            viewEditDropDataRef.current?.videoThumbnailURI
          );
          setBannerVideoSrc(viewEditDropDataRef.current?.docURI);
          setDropAssetData(templateList);
          if (location?.state?.isview) {
            setActiveStep(3);
            setSteps(
              steps.map((step, index) =>
                step.value === 2 ? { ...step, IsCompleted: true } : step
              )
            );
          }
        } else {
          setBannerImgSrc(viewEditDropDataRef.current?.docURI);
          setDropAssetData(templateList);
          if (location?.state?.isview) {
            setActiveStep(3);
            setSteps(
              steps.map((step, index) =>
                step.value === 2 ? { ...step, IsCompleted: true } : step
              )
            );
          }
        }
        // for update drop banner image , convert img url to file
        if (viewEditDropDataRef.current?.docURI?.split(".").pop() !== "mp4") {
          let img = new Image(),
            canvas = document.createElement("canvas"),
            ctx = canvas.getContext("2d"),
            src = viewEditDropDataRef.current?.docURI;

          img.crossOrigin = "Anonymous";

          img.onload = function () {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            let base64Img = canvas.toDataURL("image/png");
            let fileName = viewEditDropDataRef.current?.docURI.replace(
              /^.*[\\\/]/,
              ""
            );
            let file = dataUrlToFile(base64Img, fileName);
            setDropBannerImg(file);
          };
          img.src = src;
        }
      }
    }
    const dbModal = {
      scriptName:
        location?.state?.isview === undefined ||
        viewEditDropDataRef.current?.b_C_Status === false
          ? 8
          : 11,
    };
    const script = await getBlockChainScriptFromDB(dbModal);
    createDropScript.current = script;
  };
  const fetchDropDetailsByUuId = async (dropUuId) => {
    dispatch(setLoader(true));
    DropService.getDropDetailsByUuId(dropUuId)
      .then((result) => {
        if (result?.data?.payload) {
          viewEditDropDataRef.current = result?.data?.payload;
          if (viewEditDropDataRef.current?.airDropConfigurationId) {
            setIsWhitelistSection(!isWhitelistSection);
            setAddWhitelistSwitch(!addWhitelistSwitch);
          }
          if (viewEditDropDataRef.current?.userPurchaseLimit === 0) {
            setAddClaimLimit(!addClaimLimit);
            setClaimValue(1);
            setIsMaxClaim(!isMaxClaim);
          } else {
            setClaimValue(viewEditDropDataRef.current?.userPurchaseLimit);
          }
          let dropData = {
            dropName: viewEditDropDataRef.current?.title?.replace(replaceslashwithquote, "$1"),
            dropPrice: parseFloat(viewEditDropDataRef.current?.price).toFixed(
              2
            ),
            dropDescription: viewEditDropDataRef.current?.description?.replace(replaceslashwithquote, "$1"),

            PublicSaleStartDate: location?.state?.isview
              ? new Date(
                  viewEditDropDataRef.current?.publicSalesStartDate + "000Z"
                )
              : viewEditDropDataRef.current?.publicSalesStartDate,

            PublicSaleEndDate: location?.state?.isview
              ? new Date(
                  viewEditDropDataRef.current?.publicSaleEndDate + "000Z"
                )
              : viewEditDropDataRef.current?.publicSaleEndDate,
            dropType: {
              label:
                viewEditDropDataRef.current?.dropTypeId === 1
                  ? "Drop"
                  : "Air Drop",
              value: viewEditDropDataRef.current?.dropTypeId,
            },
            whitelistOptions: {
              value: viewEditDropDataRef.current?.airDropConfigurationId,
            },
          };

          setDropDetailData(dropData);

          fetchDropTemplates();
        }
      })
      .catch((error) => {
        dispatch(setLoader(false));
      });
  };
  /** Button Click Handlers */
  const AddRemoveArtWorkHandler = (e, data) => {
    setSelectedAssetArray([]);
    if (e.currentTarget.checked) {
      setSelectedAssetArray([data]);
      setImageChecked(data.id);
    } else {
      setImageChecked("");
      setSelectedAssetArray([]);
    }
  };
  /** callback function for getting cropped image data */
  const getCroppedImgFile = (imgFile) => {
    croppedImgUrl.current = imgFile;
    setshowCroppingPopup(false);
    setBannerImgSrc(URL.createObjectURL(croppedImgUrl.current));
  };
  /** search assets in input field handler */
  const SearchAssetByNameHandler = (e) => {
    let SearchVal;
   
    if (e) {
      SearchVal = e?.target?.value 
      setAssetSearchValue(e.target.value)
    } else {
      SearchVal = e;
      setAssetSearchValue(e.target.value)
    }
    let queryResult = [];
    let tempData = AssetsArray;
    if (SearchVal) {
      if (AssetsArray.length === 0) tempData = originalAssetsArray.current;
      tempData.map((val, i) => {
        if (
          SearchVal &&
          val?.assetName.toLowerCase().includes(SearchVal.toLowerCase())
        ) {
          queryResult = [...queryResult, val];
          setAssetsArray(queryResult);
        } else if (AssetsArray.length - 1 === i && queryResult.length === 0) {
          setAssetsArray([]);
        }
      });
    } else {
      setAssetsArray(originalAssetsArray.current);
    }
  };
  /** event change handlers */
  const publicSaleStartDateHandler = (e) => {
    if (e) setPublicSaleStartDate(e);
    else setPublicSaleStartDate(null);
  };
  const publicSaleEndDateHandler = (e) => {
    if (e) setPublicSaleEndDate(e);
    else setPublicSaleEndDate(null);
  };
  const priceInputChangeHandler = (e) => {
    const priceInput = e.target.value;
    if (priceInput === "0") e.target.value = "";
    else if (isNaN(priceInput))
      e.target.value = priceInput
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*?)\..*/g, "$1");
    else {
      e.target.value =
        priceInput.indexOf(".") >= 0
          ? priceInput.substr(0, priceInput.indexOf(".")) +
            priceInput.substr(priceInput.indexOf("."), 3)
          : priceInput?.length <= 9
          ? priceInput
          : priceInput.substr(0, 9);
    }
    setDropPrice(e.currentTarget.value);
  };
  const priceBlurHandler = (e) => {
    if (e.currentTarget.value) {
      e.currentTarget.value = parseFloat(e.currentTarget.value).toFixed(2);
      setDropPrice(e.currentTarget.value);
    }
  };
  const dropNameHandler = (e) => {
    const value = e.target.value;
    setDropName(value);
  };
  const dropDescriptionChangeHandler = (e) => {
    const value = e.target.value;
    setDropDescription(value);
  };
  /** drop banner image and video change handler */
  const dropFileUploaderHandler = async (e) => {
    setBannerImgSrc("");
    setBannerVideoSrc("");
    setBannerThumbnailSrc("");
    videoThumbnailSrc.current = "";
    const file_data = e.target.files[0];
    let reg = /(.*?)\.(jpg|jpeg|PNG|png|gif|mp4)$/;
    if (file_data === undefined || !file_data.name?.toLowerCase().match(reg)) {
      e.target.value = null;
      return false;
    }
    if (file_data.size > 20000000) {
      setOpenAlertModal(true);
      setAlertContent({
        title: "Error",
        description: "File size should be less than 20 MB ",
      });
      return false;
    }
    if (file_data.name.split(".").pop() !== "mp4") {
      let reader = new FileReader();
      reader.readAsDataURL(file_data);
      let img = new Image();
      dispatch(setLoader(true));
      reader.onload = (fileList) => {
        img.onload = () => {
          if (img.width < 650 || img.height < 350) {
            setBannerImgSrc("");
            setDropBannerImg("");
            e.target.value = null;
            dispatch(setLoader(false));
            setOpenAlertModal(true);
            setAlertContent({
              title: "Error",
              description:
                "Image dimension must be greater than or equal to 650 width * 350 height ",
            });
            return false;
          } else {
            const maxWidth = img.width > 2000 ? 2000 : img.width;
            const maxHeight = img.height > 2000 ? 2000 : img.height;
            FileResizer.imageFileResizer(
              file_data,
              maxWidth,
              maxHeight,
              file_data.type.split("/")[1],
              100,
              0,
              (result) => {
                dispatch(setLoader(false));

                const blob = result;

                let fileName = v4() + "." + file_data.type.split("/")[1];
                let file = dataUrlToFile(blob, fileName);
                setBannerImgSrc(URL.createObjectURL(file));
                setAssetUploadingDiv("hidden");
                setDropBannerImg(file);
                setshowCroppingPopup(true);
              },
              "File"
            );
          }
        };
        img.src = fileList.target.result;
      };
    } else {
      const url = URL.createObjectURL(file_data);
      setBannerVideoSrc(url);
      setAssetUploadingDiv("hidden");
      setDropBannerImg(file_data);
      setBannerThumbnailSrc(url);
    }
  };
  const crossClickHandler = (e) => {
    if (location?.state?.isview != undefined) return;
    setBannerImgSrc("");
    setBannerVideoSrc("");
    setAssetUploadingDiv("");
    setBannerThumbnailSrc("");
    videoThumbnailSrc.current = "";
    setVideoThumbnailViewOnly("");
  };

  const ValidationErrorMessagesHandler = (message) => {
    dispatch(setLoader(false));
    setOpenAlertModal(true);
    setAlertContent({
      title: "Error",
      description: message,
    });
  };
  /** this method will create/update drop on db as well as on blockchain */
  const DropCreationHandler = async () => {
    let AirDropConfigurationId = null;
    let dropId = null;
    dispatch(setLoader(true));
    dispatch(
      setLoaderMessage(
        "Please wait. Your drop is being created on the blockchain."
      )
    );
    if (isWhitelistSection) {
      if (dropDetailData?.addAirDropFile) {
        const response = await addAirDropUser();
        if (response) {
          AirDropConfigurationId = response;
        } else {
          return;
        }
      } else {
        AirDropConfigurationId = dropDetailData?.whitelistOptions?.value;
      }
    }

    let bDropUpdateCall = location?.state?.isview === undefined ? false : true;
    const CurrentDateForUpdateDrop = moment().toISOString();
    const updateDropPublicDate =
      viewEditDropDataRef.current?.publicSalesStartDate;
    if (
      moment(dropDetailData?.PublicSaleStartDate).format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD") &&
      moment(dropDetailData?.PublicSaleStartDate).format("HH:mm:ss") <
        moment().format("HH:mm:ss")
    ) {
      ValidationErrorMessagesHandler(
        "Public sale start time should be greater than current time"
      );
      return;
    } else if (
      moment(dropDetailData?.PublicSaleStartDate).format("YYYY-MM-DD") <
      moment().format("YYYY-MM-DD")
    ) {
      ValidationErrorMessagesHandler(
        "Public sale start date should be greater than current date"
      );
      return;
    } else if (
      moment(dropDetailData?.PublicSaleEndDate).format("YYYY-MM-DD") <
      moment(dropDetailData?.PublicSaleStartDate).format("YYYY-MM-DD")
    ) {
      ValidationErrorMessagesHandler(
        "Public sale end date should be greater than public sale start date"
      );
      return;
    } else if (
      bDropUpdateCall &&
      moment(updateDropPublicDate).isBefore(
        moment(CurrentDateForUpdateDrop?.split(".")[0])
      )
    ) {
      ValidationErrorMessagesHandler(
        "Drop already started, you cannot update public sale start date."
      );
      return;
    }
    if (location?.state?.isview) return;

    let utcStartDate =
      moment(dropDetailData?.PublicSaleStartDate).format("YYYY-MM-DD") +
      "T" +
      moment(dropDetailData?.PublicSaleStartDate).format("HH:mm:ss");
    let utcEndDate =
      moment(dropDetailData?.PublicSaleEndDate).format("YYYY-MM-DD") +
      "T" +
      moment(dropDetailData?.PublicSaleEndDate).format("HH:mm:ss");

    utcStartDate = moment(utcStartDate).toISOString();
    utcEndDate = moment(utcEndDate).toISOString();
    let txId = "";
    let formData = new FormData();
    let VideoUri = "";
    let BannerImgUri = "";

    let file = !bDropUpdateCall
      ? croppedImgUrl.current ?? DropBannerImg
      : undefined;

    if (bannerVideoSrc && !bDropUpdateCall) {
      formData.append("vidoeFile", file);
      formData.append("bannerThumbImg", videoThumbnailSrc.current);
      const resp = await DropService.uploadDropVideo(clientUuid, formData)
        .then((data) => {
          return data;
        })
        .catch((error) => {
          dispatch(setLoader(false));
          dispatch(setLoaderMessage(""));
          setOpenAlertModal(true);
          setAlertContent({
            title: "Error",
            description: "Error occured while uploading drop video ",
          });
          return;
        });
      if (resp?.data?.success && resp?.data?.payload) {
        VideoUri = resp?.data?.payload?.videoUri;
        BannerImgUri = resp?.data?.payload?.originalUri;
      }
    }

    let dropName = dropDetailData?.dropName
      ?.replace(removelineSpace, "")
      .replace(replaceQuotes, "\\$&");

    let description = dropDetailData?.dropDescription
      ?.replace(removelineSpace, "")
      .replace(replaceQuotes, "\\$&")
      .trim();

    let data = {
      DropId: dropId,
      Title: dropName,
      Description: description,
      ClientId: clientUuid,
      PublicSalesStartDate: utcStartDate.substring(0, utcStartDate.length - 5),
      PublicSaleEndDate: utcEndDate.substring(0, utcEndDate.length - 5),
      DropPrice:
        parseFloat(dropDetailData?.dropType?.value) === 2
          ? 0
          : dropDetailData?.dropPrice,
      Uuid: bDropUpdateCall ? location?.state?.uuid : v4(),
      File: file,
      DropVideoUri: VideoUri,
      VideoThumbnailUri: BannerImgUri,
      LoggedInUserId: userInfo?.user_id,
      DropAssetList: { AssetIds: dropAssetData?.[0]?.id  },
      BCTransactionId: txId,
      DropTypeId: dropDetailData?.dropType?.value,
      AirDropConfigurationId: AirDropConfigurationId,
      UserPurchaseLimit:
        parseFloat(dropDetailData?.dropType?.value) === 2
          ? addClaimLimit
            ? 0
            : claimValue?.toString()
          : 0,
    };
    
    
    if (bDropUpdateCall) {
      data.DocURI = viewEditDropDataRef.current?.docURI;
      data.DropVideoUri = data.DocURI;
      data.VideoThumbnailUri = viewEditDropDataRef.current?.videoThumbnailURI;
    }
    BuildFormData(formData, data);
    if (bannerVideoSrc && !bDropUpdateCall) {
      formData.delete("file");
      formData.delete("File");
    }
   // const matchWalletResponse = await matchConnectedWallet(clientWalletAddress);
   // if (matchWalletResponse) {
      {
        /** this flow will run if drop creation fail on blockchain */
      }
      // if (retryBlockChain?.current?.dropId) {
      //   const txResult = await creat_Update_Drop_On_BC(
      //     utcStartDate,
      //     utcEndDate,
      //     retryBlockChain.current.dropId
      //   );

      //   if (!txResult?.error) {
      //     handleDropResponse(
      //       retryBlockChain.current.dropId,
      //       txResult?.message?.txId
      //     );
      //   } else {
      //     handleDropErrorResponse(txResult);
      //   }
      //   return;
      // }

      let isActiveDrop = viewEditDropDataRef.current?.b_C_Status ?? false;
      if (!bDropUpdateCall || !isActiveDrop) {
        const createUpdateDropReslt = await creat_Update_Drop_On_Db(formData);
        // if (createUpdateDropReslt) {
        //   const txResult = await creat_Update_Drop_On_BC(
        //     utcStartDate,
        //     utcEndDate,
        //     createUpdateDropReslt
        //   );
        const TxId = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
          /[018]/g,
          (c) =>
            (
              c ^
              (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
            ).toString(16)
        );
          //if (TxId) {
            const modal = {
              DropId: createUpdateDropReslt,
              TransactionId: TxId,
              ClientId:clientUuid
            };
            DropService.updateDropTx(modal)
              .then((data) => {
                if (data?.data?.payload) {
                  dispatch(setLoader(false));
                  dispatch(setLoaderMessage(""));
                  //  setOpenAlertModal(true);

                  setopenSuccess(true);
                  setAlertContent({
                    title: "Success",
                    description:
                      "Your drop has been created, you can now view it on your storefront",
                    path: "/drop-list",
                    subTitle: "Drop created successfully ",
                    btnTitle: "Back to Drop",
                  });
                } else {
                  dispatch(setLoader(false));
                  dispatch(setLoaderMessage(""));
                  setopenSuccess(true);
                  setAlertContent({
                    title: "Success",
                    description:
                      "Your drop has been created, you can now view it on your storefront.",
                    subTitle: "Drop created successfully ",
                    path: "/drop-list",
                    btnTitle: "Back to Drop",
                  });
                }
              })
              .catch((error) => {
                dispatch(setLoader(false));
                dispatch(setLoaderMessage(""));
                setOpenAlertModal(true);
                setAlertContent({
                  title: "Error",
                  description:
                    "Drop created successfully but failed to update blockchain Tx Id in DB",
                  path: "/drop-list",
                });
              });
          // } else {
          //   dispatch(setLoader(false));
          //   dispatch(setLoaderMessage(""));
          //   setOpenAlertModal(true);
          //   let defaultErrMsg = "Drop creation failed at blockchain level ";
          //   let errDescription = BlockChainErrorsHandler(
          //     txResult,
          //     defaultErrMsg
          //   );
          //   setAlertContent({
          //     title: "Error",
          //     description: errDescription,
          //   });
          //   retryBlockChain.current = { dropId: createUpdateDropReslt };
          // }
        //}
      // } else {
      //   const txResult = await creat_Update_Drop_On_BC(
      //     utcStartDate,
      //     utcEndDate,
      //     viewEditDropDataRef.current?.id
      //   );
        // if (!txResult?.error) {
        //   formData.append("BCTransactionId", txResult?.message?.txId);
        //   const createUpdateDropReslt = await creat_Update_Drop_On_Db(formData);
        //   if (createUpdateDropReslt) {
        //     dispatch(setLoader(false));
        //     dispatch(setLoaderMessage(""));
        //     setopenSuccess(true);
        //     setAlertContent({
        //       title: "Success",
        //       description:
        //         "Your drop has been updated, you can now view it on your storefront.",
        //       subTitle: "Drop updated successfully",
        //       path: "/drop-list",
        //       btnTitle: "Back to Drop",
        //     });
        //   } else {
        //     dispatch(setLoader(false));
        //     dispatch(setLoaderMessage(""));
        //     setOpenAlertModal(true);
        //     setAlertContent({
        //       title: "Error",
        //       description: "Drop updation failed at db level",
        //     });
        //   }
        // } else {
        //   dispatch(setLoader(false));
        //   dispatch(setLoaderMessage(""));
        //   setOpenAlertModal(true);
        //   let defaultErrMsg = "Drop updation failed at blockchain level ";
        //   let errDescription = BlockChainErrorsHandler(txResult, defaultErrMsg);
        //   setAlertContent({
        //     title: "Error",
        //     description: errDescription,
        //   });
        // }
      }
    // } else {
    //   if (matchWalletResponse === null) {
    //     dispatch(setLoader(false));
    //     dispatch(setLoaderMessage(""));
    //   } else {
    //     dispatch(setLoader(false));
    //     dispatch(setLoaderMessage(""));
    //     setOpenInvalidWalletModal(true);

    //     await disconnetWallet();
    //   }
    // }
  };
  const creat_Update_Drop_On_Db = async (formData) => {
    return DropService.createUpdateDrop(formData)
      .then(async (res) => {
        if (res?.data?.payload) {
          const dropId = res?.data?.payload;
          return dropId;
        } else {
          dispatch(setLoader(false));
          dispatch(setLoaderMessage(""));
          return null;
        }
      })
      .catch((error) => {
        setOpenAlertModal(true);
        setAlertContent({
          title: "Error",
          description:
            error?.response?.data?.message ??
            "Error while creating Drop.Please try again!",
        });
        dispatch(setLoader(false));
        dispatch(setLoaderMessage(""));
        return null;
      });
  };

  const creat_Update_Drop_On_BC = async (utcStartDate, utcEndDate, dropId) => {
    let dropPrice =
      parseFloat(dropDetailData?.dropType?.value) === 2
        ? 0
        : dropDetailData?.dropPrice;
    // let dropTypeID = dropDetailData?.dropType?.value;
    let dropdescription = dropDetailData?.dropDescription
      ?.replace(removelineSpace, "")
      .replace(replaceQuotes, "\\$&")
      .trim();

    let dropName = dropDetailData?.dropName
      ?.replace(removelineSpace, "")
      .replace(replaceQuotes, "\\$&")
      .trim();

    let limit =
      parseFloat(dropDetailData?.dropType?.value) === 2
        ? addClaimLimit
          ? 0
          : claimValue?.toString()
        : 0;
     
    const txResult = await Transactions.ExecuteTransactionsFunction(
      script.CreateUpdateDropScript(
        createDropScript,
        dropId,
        blockChainBrandId,
        dropName,
        dropdescription, //"Bloomly Drop Description", //HARDCODED as admin can enter special chars and in blockchain , error appear , will handle in future
        moment(utcStartDate).unix() + ".0",
        moment(utcEndDate).unix() + ".0",
        parseFloat(dropPrice).toFixed(2),
        dropAssetData?.[0]?.id,
        dropDetailData?.dropType?.value,
        limit
      )
    );

    return txResult;
  };

  const handleDropResponse = (dropId, txId) => {
    const modal = {
      DropId: dropId,
      TransactionId: txId,
      ClientId:clientUuid
    };
    DropService.updateDropTx(modal)
      .then((data) => {
        if (data?.data?.payload) {
          dispatch(setLoader(false));
          dispatch(setLoaderMessage(""));
          //  setOpenAlertModal(true);

          setopenSuccess(true);
          setAlertContent({
            title: "Success",
            description:
              "Your drop has been created, you can now view it on your storefront",
            path: "/drop-list",
            subTitle: "Drop created successfully ",
            btnTitle: "Back to Drop",
          });
        } else {
          dispatch(setLoader(false));
          dispatch(setLoaderMessage(""));
          setopenSuccess(true);
          setAlertContent({
            title: "Success",
            description:
              "Your drop has been created, you can now view it on your storefront.",
            subTitle: "Drop created successfully ",
            path: "/drop-list",
            btnTitle: "Back to Drop",
          });
        }
      })
      .catch((error) => {
        dispatch(setLoader(false));
        dispatch(setLoaderMessage(""));
        setOpenAlertModal(true);
        setAlertContent({
          title: "Error",
          description:
            "Drop created successfully but failed to update blockchain Tx Id in DB",
          path: "/drop-list",
        });
      });
  };
  const handleDropErrorResponse = (txResult) => {
    dispatch(setLoader(false));
    dispatch(setLoaderMessage(""));
    setOpenAlertModal(true);
    let defaultErrMsg = "Drop creation failed at blockchain level ";
    let errDescription = BlockChainErrorsHandler(txResult, defaultErrMsg);
    setAlertContent({
      title: "Error",
      description: errDescription,
    });
  };

  // >>>>>>>>> CLAIM METHODS
  const claimValuePlusHandler = (e) => {
    e.preventDefault();
    let claimVal = claimValue;
    if (parseInt(claimVal) === 0) claimVal = 0;
    if (parseInt(claimVal) >= 100000) claimVal = 100000;
    else claimVal = parseInt(claimVal + 1);

    setClaimValue(claimVal);
    claimMaxRef.current = claimVal;
    claimMaxRef?.current?.focus();
  };
  const claimValueMinusHandler = (e) => {
    e.preventDefault();
    let claimVal = claimValue;
    claimVal = claimVal - 1;
    if (claimVal <= 1) claimVal = 1;
    setClaimValue(claimVal);
    claimMaxRef.current = claimVal;
    claimMaxRef?.current?.focus();
  };

  const claimInputChangeHandler = (evt) => {
    let claim_value_local = evt.target.validity.valid
      ? evt.target.value
      : claimValue;
    if (claim_value_local !== "") {
      if (parseInt(claim_value_local) === 0) claim_value_local = 1;
      if (parseInt(claim_value_local) > 1000000) claim_value_local = 10;
      setClaimValue(parseInt(claim_value_local));
      claimMaxRef.current = parseInt(claim_value_local);
    } else {
      setClaimValue(claim_value_local);
      claimMaxRef.current = claim_value_local;
    }
  };

  const addAirDropUser = async () => {
    let formData = new FormData();
    formData.append("ClientId", clientUuid);
    formData.append("UserListName", dropDetailData?.whitelistName);
    formData.append("UsersListFile", dropDetailData?.airdropFile[0]);
    return await DropService.createAirdropUserList(formData)
      .then(async (response) => {
        if (response?.data?.statusCode === 200) {
          return response?.data?.payload?.id;
        } else {
          dispatch(setLoader(false));
          dispatch(setLoaderMessage(""));
          return null;
        }
      })
      .catch((error) => {
        setOpenAlertModal(true);
        setAlertContent({
          title: "Error",
          description:
            error?.response?.data?.message ?? "Error while whitelisting User !",
        });
        dispatch(setLoader(false));
        dispatch(setLoaderMessage(""));
        return null;
      });
  };

  return (
    <>
      <main className="relative z-20 ">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-2xl font-bold text-white">
            {location?.state?.isview === true
              ? "View Drop Details"
              : location?.state?.isEdit === true
              ? "Update Drop"
              : "Create New Drop"}
          </h1>
          <div className="flex items-center justify-between">
            <WalletAddress walletAddress={clientWalletAddress} />
            <UserAvatar
              firstname={contactFirstName}
              lastname={contactLastName}
              pic={images.avatar}
            />
          </div>
        </div>
        {/** stepper start */}
     {!location?.state?.isview && <StepperComponent steps={steps} activeStep={activeStep} />}   

        {/** stepper end */}
        {/* my content */}
        {activeStep === 1 && (
          <CreateDropDetail
            assetUploadingDiv={assetUploadingDiv}
            dropFileUploaderHandler={dropFileUploaderHandler}
            bannerVideoSrc={bannerVideoSrc}
            bannerImgSrc={bannerImgSrc}
            crossClickHandler={crossClickHandler}
            bannerThumbnailSrc={bannerThumbnailSrc}
            dataUrlToFile={dataUrlToFile}
            videoThumbnailSrc={videoThumbnailSrc}
            AssetsArray={AssetsArray}
            imageChecked={imageChecked}
            AddRemoveArtWorkHandler={AddRemoveArtWorkHandler}
            setSelectCheckboxes={setSelectCheckboxes}
            steps={steps}
            setSteps={setSteps}
            setActiveStep={setActiveStep}
            setDropDetailData={setDropDetailData}
            dropDetailData={dropDetailData}
            viewEditData={location?.state}
            videoThumbNailViewOnly={videoThumbNailViewOnly}
            claimValuePlusHandler={claimValuePlusHandler}
            claimValueMinusHandler={claimValueMinusHandler}
            claimInputChangeHandler={claimInputChangeHandler}
            claimMaxRef={claimMaxRef}
            claimValue={claimValue}
            setClaimValue={setClaimValue}
            isMaxClaim={isMaxClaim}
            setIsMaxClaim={setIsMaxClaim}
            addWhitelistSwitch={addWhitelistSwitch}
            setAddWhitelistSwitch={setAddWhitelistSwitch}
            isWhitelistSection={isWhitelistSection}
            setIsWhitelistSection={setIsWhitelistSection}
            addClaimLimit={addClaimLimit}
            setAddClaimLimit={setAddClaimLimit}
            selectedWhitelistOption={selectedWhitelistOption}
            setSelectedWhitelistOption={setSelectedWhitelistOption}
            airdropUserList={airdropUserList}
            setAirdropUserList={setAirdropUserList}
          />
        )}
        {activeStep === 2 && (
          <AddDropAsset
            viewEditData={location?.state}
            SearchAssetByNameHandler={SearchAssetByNameHandler}
            setActiveStep={setActiveStep}
            steps={steps}
            setSteps={setSteps}
            setDropAssetData={setDropAssetData}
            setAssetDrop={setAssetsArray}
            assetDrop={AssetsArray}
            originalAssetsArrayRefVal={originalAssetsArray.current}
            assetSearchValue={assetSearchValue}
          />
        )}
        {activeStep === 3 && (
          <DropReview
            viewEditData={location?.state}
            dropAssetData={dropAssetData}
            dropDetailData={dropDetailData}
            bannerImgSrc={bannerImgSrc}
            bannerVideoSrc={bannerVideoSrc}
            setActiveStep={setActiveStep}
            DropCreationHandler={DropCreationHandler}
            videoThumbNailViewOnly={videoThumbNailViewOnly}
          />
        )}
      </main>
      {showCroppingPopup && (
        <Modal
          modalVisible={showCroppingPopup}
          closeModal={setshowCroppingPopup}
          children={
            <ImageCropper
              imgSrc={bannerImgSrc}
              getCroppedImgFile={getCroppedImgFile}
              aspectRatio={16 / 9}
            />
          }
          modal_Id="Add_Modal"
          isClose={true}
        />
      )}
      {OpenAlertModal && (
        <ModalLayout
          modalVisible={OpenAlertModal}
          closeModal={setOpenAlertModal}
          children={<AlertModalContent message={alertContent} />}
          modal_Id="Add_Modal"
          isClose={true}
        />
      )}
      {openSuccess && (
        <Modal
          modalVisible={openSuccess}
          closeModal={setopenSuccess}
          children={<ThanksModalContent message={alertContent} />}
          modal_Id="Add_Modal"
          isClose={true}
        />
      )}
      {openInvalidWalletModal && (
        <Modal
          modalVisible={openInvalidWalletModal}
          closeModal={setOpenInvalidWalletModal}
          children={<InvalidWalletContent />}
          modal_Id="Delete_Modal"
          isClose={true}
        />
      )}
    </>
  );
};
export default CreateDrop;
